


































import { defineComponent } from '@nuxtjs/composition-api';
import AlgoliaSiteSearchSuggestions from '@vaimo-algolia/components/search/AlgoliaSiteSearchSuggestions.vue';

export default defineComponent({
  name: 'CategoryEmptyResults',
  components: {
    AlgoliaSiteSearchSuggestions,
  },
  props: {
    searchQuery: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: 'We don\'t currently have any results for \“{0}\”',
    },
    showSuggestions: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
});
