




































import { ref, computed, defineComponent, useContext, watch } from '@nuxtjs/composition-api';
import {
  AisConfigure,
  AisHits,
  AisStateResults,
  AisIndex,
} from 'vue-instantsearch';

export default defineComponent({
  name: 'AlgoliaSiteSearchSuggestions',
  components: {
    AisConfigure,
    AisHits,
    AisStateResults,
    AisIndex,
  },
  props: {
    query: {
      type: String,
      required: true,
      default: '',
    },
    suggestionsAmount: {
      type: Number,
      required: false,
      default: 3,
    }
  },
  setup(props) {
    const {
      i18n,
      $algoliaSearch: {
        searchClient,
        indexName,
      }
    } = useContext();
    const querySuggestionsIndexName = ref(`${indexName}_query_suggestions`);

    const createLink = (synonym: string) => {
      return `<a href="/search?query=${encodeURIComponent(synonym)}">${synonym}</a>`;
    };

    const getHitsSuggestions = (hits: any[]): string => {
      const items = hits
        .map((item) => item?.query ?? '')
        .filter(Boolean)
        .map(createLink);

      if (items.length === 0) {
        return '';
      }

      if (items.length < 3) {
        return items.join(', ')
      }

      const lastSuggestion = items.pop();

      return i18n.t('{suggestion} or {lastSuggestion}', {
        suggestion: items.join(', '),
        lastSuggestion,
      }) as string;
    };

    return {
      querySuggestionsIndexName,
      getHitsSuggestions,
    };
  },
});
